import React, { useState, useEffect } from 'react';

import './Popup.scss';

const Popup = ({ imageUrl, storeName, showLabelAds, labelAddress, onClose, link, onTryAgain }) => {

    const [isMounted, setIsMounted] = useState(false);
    const [isTryingAgain, setIsTryingAgain] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setIsMounted(true);
        }, 300)
    }, []);

    const handleMultipleAction = () => {
        onTryAgain();

        setIsTryingAgain(true);
        setTimeout(() => {
            setIsTryingAgain(false);
        }, 800)
    }

    return (
        <div>
            <div className={`backdrop fade-in ${isMounted ? 'show' : ''}`} onClick={onClose}></div>
            <div className={`popup fade-in delay-300 ${isMounted ? 'show' : ''}`}>
                <div className="popup-header">
                    <img src={imageUrl} alt="Popup Header" />
                    <button className='close' onClick={onClose}>&#10005;</button>
                </div>
                <div className="popup-body">
                    <div className="heading">
                        <h2>{storeName}</h2>
                        {showLabelAds !== '0' && (<p className='ads'>Đây là quảng cáo</p>)}
                    </div>
                    <p className='address'>{labelAddress}</p>
                </div>
                <div className="popup-footer">
                    <div className="btn-group">
                        <a className='btn' href={link} target='_blank'>
                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 19.3333C11.7725 19.3333 11.5438 19.2668 11.3455 19.1327C11.083 18.9565 4.9195 14.781 3.0085 12.8665C0.872334 10.7303 0.625 8.45999 0.625 6.93749C0.625 3.47949 3.43783 0.666656 6.89583 0.666656C8.99817 0.666656 10.8602 1.70616 12 3.29866C13.1398 1.70616 15.0018 0.666656 17.1042 0.666656C20.5622 0.666656 23.375 3.47949 23.375 6.93749C23.375 8.45999 23.1277 10.7303 20.9915 12.8665C19.077 14.781 12.9147 18.9565 12.6545 19.1327C12.4562 19.2668 12.2275 19.3333 12 19.3333ZM6.89583 2.99999C4.72467 2.99999 2.95833 4.76632 2.95833 6.93749C2.95833 8.21266 3.16017 9.71882 4.65817 11.2168C6.07217 12.6308 10.355 15.621 12 16.7538C13.645 15.621 17.9278 12.6308 19.3418 11.2168C20.8398 9.71882 21.0417 8.21266 21.0417 6.93749C21.0417 4.76632 19.2753 2.99999 17.1042 2.99999C14.933 2.99999 13.1667 4.76632 13.1667 6.93749C13.1667 7.58149 12.6452 8.10416 12 8.10416C11.3548 8.10416 10.8333 7.58149 10.8333 6.93749C10.8333 4.76632 9.067 2.99999 6.89583 2.99999Z" fill="white" />
                            </svg>
                            <span>Love it!</span>
                        </a>
                        <button className={`btn btn-secondary ${isTryingAgain ? 'rotate-icon' : ''}`} onClick={handleMultipleAction}>
                            <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.5885 13.0717L15.1962 7.07175C14.2396 6.51946 13.0164 6.84721 12.4641 7.8038L6.46411 18.1961C5.91183 19.1527 6.23958 20.3759 7.19616 20.9282L17.5885 26.9282C18.5451 27.4804 19.7682 27.1527 20.3205 26.1961L26.3205 15.8038C26.8728 14.8472 26.5451 13.624 25.5885 13.0717Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M14.8612 12.6519C15.1004 12.7899 15.4062 12.708 15.5442 12.4688C15.6823 12.2297 15.6004 11.9239 15.3612 11.7858C15.1221 11.6478 14.8163 11.7297 14.6782 11.9688C14.5402 12.208 14.6221 12.5138 14.8612 12.6519Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M20.9234 16.1519C21.1625 16.2899 21.4683 16.208 21.6064 15.9688C21.7445 15.7297 21.6625 15.4239 21.4234 15.2858C21.1842 15.1478 20.8784 15.2297 20.7404 15.4688C20.6023 15.708 20.6842 16.0138 20.9234 16.1519Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.4234 22.214C17.6625 22.3521 17.9683 22.2702 18.1064 22.031C18.2445 21.7919 18.1625 21.4861 17.9234 21.348C17.6842 21.21 17.3784 21.2919 17.2404 21.531C17.1023 21.7702 17.1842 22.076 17.4234 22.214Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.3612 18.714C11.6004 18.8521 11.9062 18.7702 12.0442 18.531C12.1823 18.2919 12.1004 17.9861 11.8612 17.848C11.6221 17.71 11.3163 17.7919 11.1782 18.031C11.0402 18.2702 11.1221 18.576 11.3612 18.714Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>Thử lại</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
